import { ClassReducerData } from "../../../../dtos/class";
import {
  FORM_CLASS_INITIATE,
  FORM_CURRICULUM_CHANGE,
  FORM_DETAIL_CHANGE,
  FORM_ERROR_CHANGE,
  FORM_ERROR_RESET,
  FORM_REQUIREMENT_CHANGE,
  FORM_SCHEDULE_CHANGE,
} from "./type";

const initial: ClassReducerData = {
  detail: {
    name: "",
    description: "",
    categories: [],
    price: "",
    certificate: "",
    maxPerson: 0,
  },
  error: null,
  schedule: null,
  curriculums: [],
  requirements: [],
  submission: false,
  stepActive: 0,
};

const ClassReducer = (state = initial, action) => {
  switch (action.type) {
    case FORM_CLASS_INITIATE:
      return initial;
    case FORM_DETAIL_CHANGE:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload,
        },
      };
    case FORM_SCHEDULE_CHANGE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          ...action.payload,
        },
      };
    case FORM_CURRICULUM_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case FORM_REQUIREMENT_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case FORM_ERROR_CHANGE:
      return {
        ...state,
        error: {
          ...state.error,
          ...action.payload,
        },
      };
    case FORM_ERROR_RESET:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default ClassReducer;
