import { createTheme } from "@mui/material/styles";
import { settings } from "./constants";



export const theme = createTheme({
  palette: {
    primary: {
      main: settings.primaryColor,
    },
    secondary: {
      main: settings.secondaryColor,
    },
    background: {
        paper: "#ebebeb"
    }
  },
});
