import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import appRoutes from "./configs/route";
import Loader from "./components/loader";

function App() {
  const routes = useRoutes(appRoutes);

  return <Suspense fallback={<Loader />}>{routes}</Suspense>;
}

export default App;
