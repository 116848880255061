import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../../components/loader/Loadable";

// Layout
const BlankLayout = Loadable(lazy(() => import('../../components/layouts/BlankLayout')));
const SidebarLayout = Loadable(lazy(() => import('../../components/layouts/SidebarLayout')));


// Auths
const Register = Loadable(lazy(() => import('../../modules/auths/register')));
const Login = Loadable(lazy(() => import('../../modules/auths/login')));

// Vocation
const DashboardView = Loadable(lazy(() => import('../../modules/dashboard')));
const VocationProfile = Loadable(lazy(() => import('../../modules/dashboard/vocation/Profile')));
const ClassList = Loadable(lazy(() => import('../../modules/dashboard/vocation/ClassList')));

// Student
const StudentPage = Loadable(lazy(() => import('../../modules/dashboard/student')));

// Event
const EventPage = Loadable(lazy(() => import('../../modules/dashboard/event')));


const appRoutes = [
    {
        path: '',
        element: <SidebarLayout />,
        children: [
            { path: '', element: <DashboardView /> },
            { path: 'data-vokasi', element: <VocationProfile /> },
            { path: 'daftar-kelas', element: <ClassList /> },
            { path: 'students', element: <StudentPage /> },
        ],
    },
    {
        path: 'events',
        element: <SidebarLayout />,
        children: [
            { path: '', element: <EventPage /> },
        ],
    },
    {
        path: 'auths',
        element: <BlankLayout />,
        children: [
            { path: 'sign-in', element: <Login /> },
            { path: 'sign-up', element: <Register /> },
        ],
    },
    { 
        path: '*', element: <Navigate to="/auths/login" /> 
    },
];

export default appRoutes;