import { Box, CircularProgress, Grid } from "@mui/material";

const Loader = () => (
  <Box sx={{height: '100vh'}}>
    <Grid container alignItems={"center"} justifyContent={"center"} >
      <CircularProgress color="primary" />
    </Grid>
  </Box>
);

export default Loader;